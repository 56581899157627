.newsdeatiltitle {
    font-style: normal;
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 36px !important;
    color: #262626;
    width: 90%;
}

.news-detailtext {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #262626;
    padding-bottom: 20px;
    width: 90%;
}
@media only screen and (max-width: 768px) {
    .news-detailtext {
        width: 100% !important;
    }
  }
  
.news-detailtext p{
    padding-bottom: 20px;
}
.cardComponent-newsdetail{
    margin-top: 20px;
}
.spacinfornewsdetail{
    margin-top: 4rem !important;
}

.more-information{
    width: 91% !important;
}
.posts-delivered-footer-2
{
    padding: 38px 2px 0 0 !important;

}