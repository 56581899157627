body {
  background: radial-gradient(48.48% 57.94% at 51.52% 37.6%, #061C31 0%, #061523 30.2%, #02060B 73.49%, #000000 82.27%);
  background-size: 100%;
}

.App {
  /* background-color: #000; */
  min-height: 100vh;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.border {
  border-right: 1px solid white;
}

.home-amazon-image {
  width: 100%;
}

.amazon-image {
  width: 100%;
  /* height: 600px; */
}

footer {
  margin-top: 50px !important;
}



@media (max-width: 920px) {
  footer .MuiGrid-container .footer-img {
    margin: 0 auto;
    display: block !important;
    margin-bottom: 25px;
  }

  .top-news-card {
    width: 100% !important;
    box-shadow: 3px 4px 4px rgb(0 0 0 / 71%);
  }

  .news-details-top-news-card {
    width: 100% !important;
  }

  .image-card {
    display: flex;
    width: 100% !important;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 475.98px) {

  body {
    background-color: #000 !important;
    background-image: inherit !important;
  }

  .card-list .image-card {
    display: inherit;
    width: inherit;
  }

  .card-list .image-card img {
    margin: 0 auto;
    width: 100%;
    display: block;
    width: 100%;
    height: 150px;
  }

  .image-card .image-body {
    text-align: left;
    padding: 0px !important;
  }

  .image-card .image-body p {}

  .makeStyles-LatestArticles-10 {
    text-align: left !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }

  .makeStyles-footercopyright-36 {
    display: inherit !important;
  }

  .icon-social {
    position: inherit !important;
  }

  .home-amazon-image {
    width: 100%;
  }

  .amazon-image {
    width: 100%;
    /* height: 280px; */
  }

  .border {
    border-right: none;
  }

  .sm-font-size {
    font-size: 18px !important;
    width: 306px;
    line-height: 27px !important;
  }

  .social-icon {
    width: 306px !important;
    display: flex !important;
  }

  /* .MuiButton-iconSizeMedium {
    display: none !important;
  } */

  .icon-social {
    position: relative;
    width: 240px;
    left: 32px;
    bottom: 0px;
  }

  /* li {
    width: 100px !important;
    margin-left: 2em !important;
  } */

  .selection-one .grid-one {
    /* text-align: center; */
  }

  .makeStyles-heading-5 {
    margin: 0 auto !important;
    margin-bottom: 0px !important;
    line-height: 25.3px !important;
    margin-bottom: 20px !important;
  }

  .social-icon {
    margin: 0 auto !important;
    justify-content: center !important;
    /* display: grid !important; */
    /* height: 151px !important; */
    height: 75px !important;
  }

  .simple-text {
    text-align: left;
    /* padding: 5px 20px 20px 20px; */
    width: auto !important;
  }

  .makeStyles-advertisment-2 {
    position: relative;
  }

  .makeStyles-LatestArticles-9 {
    padding: 5px 20px 20px 20px;
  }

  #bootstrap-input {
    margin: 0 25px 0px 25px;
  }

  .contact-container .lable {
    padding: 0 25px 0px 25px;
  }

  .contact-container button {
    margin: 40px 12px 0px 12px;
  }

  .pagination {
    display: flex;
    width: 100%;
    margin: 0;
    justify-content: center;
  }

  .previous,
  .next {
    display: none;
  }
}


.simple-text {
  color: #C1C1C1;
  width: 100%;
  padding-bottom: 20px;
  ;
}


.top-news-card {
  width: 90%;
  box-shadow: 3px 4px 4px rgb(0 0 0 / 71%);
}

.news-details-top-news-card {
  width: 90%;
}

.image-card {
  display: flex;
  width: 90%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.image-card .image-body {
  padding-left: 35px;
  color: #fff;
  background: rgba(0, 0, 0, 0.08);
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.71);
  padding-right: 10px;
}

.image-body {
  height: 150px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.text-primary {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  margin-bottom: 13px;
}

/* .image-card .text-secondary {
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: #E7E7E7;
} */

.image-card .text-secondary {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #D6D6D6;
  margin-top: 0 !important;
}

.card-post-deliverd-title-1 {
  padding: 35px 55px 32px 55px;
  text-align: center;
  font-family: 'Inter' !important;
  font-style: normal;
  line-height: 25px !important;
}

.card-post-deliverd-title-2 {
  padding-left: 55px;
  padding-right: 55px;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 18px !important;
  text-align: center;
}

footer ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;

}

footer ul a {
  text-decoration: none;
}

footer li {
  margin: 1em;
  margin-left: 3em;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #E7E7E7;
}

footer li .right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow {
  border: solid #E7E7E7;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 10px;
}

.copyright {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #979797;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card-list-link {
  text-decoration: none;
}

.ads div {
  width: 100% !important;
  margin-bottom: 10px !important;
}

.ads a img {
  width: 100% !important;
}


/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}

.scrollbar::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 2px solid #fff;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

#search-bar {
  margin-left: -52px !important;
  margin: 0 auto;
  width: 490px;
  height: 45px;
  padding: 0 20px;
  font-size: 1rem;
  border: 1px solid #D0CFCE;
  outline: none;

}

.searchIcon {
  cursor: pointer;
  position: absolute;
  width: 75px;
  height: 75px;
  top: -15px;
  right: -5px;
}
.searchcloseIcon {
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 10px;
  right: 10px;
}

@media screen and (max-width: 1236px) and (min-width: 1444px) {
  #search-bar {
    width: 16rem !important;
  }
}

@media screen and (max-width: 1120px) {
  .searchContainer {
    display: none !important;
  }
}

@media screen and (max-width: 1444px) {
  #search-bar {
    width: 23rem;
  }
}

@media screen and (max-width:1434px) {
  .searchIcon {
    right: -10px;
  }
}

@media screen and (max-width:1290px) {
  #search-bar {
    width: 18rem;
  }

  .searchIcon {
    right: -10px;
  }
}

@media screen and (max-width:1240px) {
  #search-bar {
    width: 15rem;
  }

  .searchIcon {
    right: -10px;
  }
}

@media screen and (max-width:1240px) {
  #search-bar {
    width: 10rem;
    margin-left: 10px;
  }

  .searchIcon {
    right: -10px;
  }
}

.searchContainer {
  max-width: 490px;
  display: block;
  margin: 80px auto;
  position: relative;
}