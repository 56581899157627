@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Work+Sans&display=swap");

@font-face {
  font-family: pt-serif;
  src: url('./assets/fonts/PTF55F.ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.container {
  color: #FFFFFF;
  min-height: 50vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiPaper-root {
  background-color: black;
}

.switch-theme {
  position: fixed !important;
  right: 0;
  top: -6px;
}

header {
  background-color: #000 !important;
}

/* //Home page css */
.light-mode .subscribe-input {
  color: #000;
}

.drawer-mobile .MuiDrawer-paper {
  background-color: #000;
}

.light-mode .drawer-mobile .MuiDrawer-paper {
  background-color: #fff !important;
}

.light-mode .menuloginButtonMobile {
  color: #000;
  background: #fff;
}



/* //Ligth mode */
.light-mode {
  background: #fff !important;
}

.light-mode header,
.light-mode .drawerContainer {
  background-color: #fff !important;
}

.light-mode .nav-bar,
.light-mode .sm-font-size,
.light-mode .simple-text,
.light-mode .text-theme,
.light-mode .image-card .image-body,
.light-mode .image-card .text-secondary,
.light-mode .cardPostDeliverd p,
.light-mode .humber,
.light-mode .card-post-deliverd-title-1,
.light-mode .card-post-deliverd-title-2 {
  color: #3E3E3E !important;
  font-weight: 600;
}

.light-mode .ticker-tape {
  background-color: #fff !important;
  box-shadow: inherit !important;
}


.light-mode .simple-text,
.light-mode .sm-font-size {
  padding: 10px;
}

.light-mode .login-button {
  background: inherit !important;
}

.light-mode .list li,
.light-mode .list li i,
.light-mode .copyright {
  color: #E7E7E7 !important;
  font-weight: 600;
}

.light-mode .list li .arrow {
  /* border: solid #E7E7E7; */
}


.light-mode .social-button,
.light-mode .drawerContainer li,
.light-mode .drawerContainer a {
  color: #000000ad !important;
  font-weight: 700;
}

.image-card .image-body {
  min-width: 451px;
}

.light-mode .image-card .image-body {
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 3px 4px 10px rgb(0 0 0 / 16%);

}

.light-mode .cardComponent,
.light-mode .cardPostDeliverd {
  background: inherit !important;
  border: 1px solid #9e9e9e;
  box-shadow: inherit !important;
}

.footer-img.light,
.header-logo-light {
  display: none;
}

.light-mode .dark,
.light-mode .header-logo-dark {
  display: none !important;
}

.light-mode .footer-img.light,
.light-mode .header-logo-light {
  display: block;
}

.light-mode footer {
  border-top: 1px solid #0066FF !important;
  background: #0066FF;
}

.light-mode .top-news-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 19px rgb(0 0 0 / 22%) !important;
}

.light-mode .news-details-top-news-card {
  background: #FFFFFF;
}

.light-mode .container {
  color: #000000ad !important;
  font-weight: 700;
}

.light-mode .about-content,
.light-mode .pp-container h6,
.light-mode .pp-container span {
  color: #262626 !important;
}

.night-mode-button,
.light-mode .light-mode-button {
  display: none !important;
}

.light-mode .night-mode-button {
  display: inline-flex !important
}


.light-mode-button,
.night-mode-button {
  background: inherit !important;
  width: 28px;
  height: 28px;
}

.night-mode-button {
  background: inherit !important;
  width: 24px;
  height: 24px;
}

.light-mode-button img {
  width: 28px;
  height: 28px;
}

.night-mode-button img {
  width: 24px;
  height: 24px;
}

.pp-container .left-content {
  margin-right: 20px
}

.pp-container .right-content {
  margin-left: 20px;
}

.pp-container .p-content {
  margin-bottom: 20px;
}


.pagination a {
  cursor: pointer;
  display: inline-block;
  padding: 10px 18px;
  color: #fff;
}

.light-mode .pagination a {
  color: #000 !important;

}

.pagination li.selected {
  background: #144bed;
}

.pagination li.selected a {
  color: #fff !important;
}

.pagination {
  padding: 30px 0;
  display: flex;
  margin: auto;
  width: max-content;
}

.pagination ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}





@media (max-width: 958px) {
  .image-card .image-body {
    min-width: auto !important;
  }

  .light-mode .image-card .image-body {
    border: inherit !important;
    padding: 7px 30px 30px 30px !important;

  }

  .container-about,
  .pp-container {
    width: auto !important;
  }

  .mt-atuo {
    margin-top: 15px;
  }

  .pp-container .left-content {
    margin-right: 0px !important
  }

  .pp-container .right-content {
    margin-left: 0px !important;
  }

}


.cardComponent>div>div {
  width: 100% !important;
}

.cardComponent>div>div>iframe {
  width: 100% !important;

}

.light-mode .cardlight {
  background: rgba(255, 255, 255, 0.19);
  box-shadow: 0px 4px 10px rgb(0 0 0 / 11%);
}


/* //Contactus css */


.light-mode .contact-container h5,
.light-mode .contact-container p,
.light-mode .contact-container div,
.light-mode .contact-container label {
  color: #242424 !important;
}

.light-mode .contact-container #bootstrap-input {
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid #D3D3D3;
}

.light-mode .contact-container button {
  color: #FFFFFF;
}

.contact-container #bootstrap-input:focus,
.subscribe-input:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.subscribe-input {
  margin-bottom: 11px !important;
}

.subscribe {
  margin: 0 auto !important;
  display: block !important;
}

.subscribe .MuiButton-label {
  padding-top: 6px;
}

@media (max-width: 475.98px) {
  .cardlight {
    height: 574px !important;
  }

  .polaris__single-slide--photo figure img {
    height: 182px !important;
  }
}

/* //Footer  */

.light-mode .icon-social img {
  filter: brightness(0) invert(1);

}





.post {
  display: flex;
  width: 600px;
  height: 150px;
  margin-bottom: 20px;
}

.post .left-col {
  height: 155px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1.5rem;
}

.post .avatar {
  width: 155px;
  height: 155px;
  background-color: #eaeaea;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.post .avatar-skeleton {
  width: 100%;
  height: 100%;
  line-height: 1;
}

.post .avatar img {
  width: 70%;
  height: 70%;
}

.post .right-col {
  flex: 1;
}

.post h3 {
  font-size: 1.3rem;
}


.news-detailtext {
  padding: 0 !important;
  width: 92% !important;
}

.posts-delivered-footer {
  padding: 18px;
  margin: 0 auto;
  width: 88%;
  margin-top: 17px;
}

.text-white {
  color: #fff;
}

.ad_inline,
.-tags-social,
.jwplayer {
  display: none;
}

.polaris__image--image {
  margin: 0;
}

.polaris__single-slide--photo figure img {
  width: 100%;
  height: 447px;
}

.more-information div {
  margin: 15px 0;
}

.more-information div {
  margin: 15px 0;
}

.more-information div a {
  color: #fff !important;
}

.more-information div span,
.more-information div,
.more-information p {
  font-family: 'pt-serif' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 27px !important;
}

.top-news-card .react-loading-skeleton {
  top: -4px;
}

.light-mode .more-information div a {
  color: #000 !important;
}

.no-shadow {
  box-shadow: inherit !important
}

.ads {
  background: inherit;
  margin-bottom: 22px;
  width: 100%;
  height: auto;
  display: inline-block;
  justify-items: self-start;
  position: unset;
}

.text-danger {
  color: #f44336db !important;
  text-transform: lowercase !important;
  font-size: 16px !important;
}

.subscribe-btn span {
  color: #fff !important;
}


.success-container {
  left: 50%;
  top: 50%;
  width: 600px;
  transform: translate(-50%, -50%);
  position: fixed;
}

.modalbox.success {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background: #fff;
  padding: 25px 25px 45px;
  text-align: center;
}

.modalbox.success.animate .icon {
  -webkit-animation: fall-in 0.75s;
  -moz-animation: fall-in 0.75s;
  -o-animation: fall-in 0.75s;
  animation: fall-in 0.75s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.modalbox.success h1 {
  font-family: 'Montserrat', sans-serif;
}

.modalbox.success p {
  font-family: 'Open Sans', sans-serif;
}

.modalbox.success .icon {
  position: relative;
  margin: 0 auto;
  margin-top: -75px;
  background: #4caf50;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.modalbox.success .icon span {
  postion: absolute;
  font-size: 4em;
  color: #fff;
  text-align: center;
  padding-top: 20px;
}

.center {
  float: none;
  margin-left: auto;
  margin-right: auto;
  /* stupid browser compat. smh */
}

.center .change {
  clearn: both;
  display: block;
  font-size: 10px;
  color: #ccc;
  margin-top: 10px;
}